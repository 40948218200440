import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

MiniLogo.propTypes = {
  sx: PropTypes.object
};

export default function MiniLogo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}>
      <CustomSVG width={140} height={140} />
    </Box>
  );
}

const CustomSVG = ({ color = '#080405', height, width = 40 }) => (
  <svg
    id="New_Layer"
    data-name="New Layer"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1920 1080"
    style={{ fill: color, width: `${width}px`, height: `${height}px` }}
  >
    <path
      className="cls-1"
      style={{
        fill: color
      }}
      d="M466.53,56.06c0,2.67,14.26,34.75,31.19,71.29,36.54,75.74,90,129.21,153.27,150.6,31.19,10.69,77.53,14.26,185.35,14.26,130.99,0,147.03,1.78,182.68,19.6,101.59,51.68,108.72,238.82,10.69,294.96-18.71,11.58-48.12,16.93-89.11,17.82l-59.7,1.78-2.67-105.15-2.67-104.26h-239.71v62.38h187.13v204.96h91.78c68.62,0,99.8-4.46,126.54-16.93,98.02-44.56,144.36-174.66,103.37-293.18-8.91-24.95-14.26-46.34-12.48-46.34,10.69,0,73.07,37.43,93.57,56.14,41.88,39.21,54.36,73.96,49.9,140.8-8.02,106.04-65.94,198.72-155.94,245.95-69.51,37.43-131.88,47.23-294.07,47.23h-136.34v-276.24h-222.78v62.38h169.31v278.92l194.26-5.35c253.97-6.24,314.56-24.06,405.46-121.19,73.96-79.31,104.26-164.86,104.26-294.07-.89-99.8-9.8-145.25-42.77-212.98-33.86-69.51-96.24-130.99-168.42-164.86l-60.6-28.52-300.3-2.67c-165.75-1.78-301.2-.89-301.2,2.67ZM1015.45,114.88c119.41,21.39,202.28,89.11,243.27,196.94l8.91,24.06-41.88-26.73c-85.55-54.36-215.65-79.31-416.15-79.31-92.68,0-122.97-3.56-151.49-16.93-34.75-15.15-102.48-81.09-102.48-98.91,0-12.48,392.09-11.58,459.81.89Z"
    />
    <g>
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M843.21,928.95v70.87c0,19.12-11.26,28.68-33.77,28.68v-10.44c13.92,0,20.89-6.08,20.89-18.24v-70.87h12.88Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M963.9,978.87c0-33.74,16.66-50.61,49.99-50.61s49.22,16.87,49.22,50.61-16.41,50.33-49.22,50.33-49.99-16.78-49.99-50.33ZM1013.89,1018.76c23.76,0,35.64-13.44,35.64-40.31s-11.88-39.75-35.64-39.75-36.41,13.25-36.41,39.75,12.14,40.31,36.41,40.31Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M1183.1,1028.51v-99.55h42.47c18.94,0,28.4,7.73,28.4,23.18,0,11.33-6.17,19.42-18.52,24.3,13.18,2.46,19.77,10,19.77,22.63,0,19.63-10.37,29.45-31.12,29.45h-41ZM1223.34,1018.07c11.88,0,17.82-6.03,17.82-18.1s-7.96-17.61-23.88-17.61h-5.57v-8.42c19.03-2.55,28.54-9.42,28.54-20.61,0-9.28-5.29-13.92-15.87-13.92h-28.4v78.67h27.36Z"
      />
      <path
        className="cls-2"
        style={{
          fill: color
        }}
        d="M1374.11,1025.03v-11.84c10.44,3.71,21.72,5.57,33.83,5.57,17.13,0,25.69-6.27,25.69-18.8,0-10.67-6.31-16.01-18.94-16.01h-12.81c-20.84,0-31.26-9.05-31.26-27.15,0-19.03,13.34-28.54,40.03-28.54,11.6,0,22.39,1.39,32.37,4.18v11.83c-9.98-3.71-20.77-5.57-32.37-5.57-18.1,0-27.15,6.03-27.15,18.1,0,10.68,6.13,16.01,18.38,16.01h12.81c21.21,0,31.82,9.05,31.82,27.15,0,19.49-12.86,29.24-38.57,29.24-12.11,0-23.39-1.39-33.83-4.18Z"
      />
    </g>
  </svg>
);
