import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// hook
import useAuth from '../hooks/useAuth';
import useNotification from '../hooks/useNotification';
import eventTypes from '../utils/eventTypes';
// ----------------------------------------------------------------------

const initialState = {
  socket: null,
  connected: false
};

const SocketContext = createContext(initialState);

SocketProvider.propTypes = {
  children: PropTypes.node
};

function SocketProvider({ children }) {
  const { isAuthenticated, user } = useAuth();
  const [connected, setConnected] = useState(false);
  const { getNotification } = useNotification();
  const socket = io(process.env.REACT_APP_API_DEALERJOBS, { autoConnect: false });

  useEffect(() => {
    const startSocket = async () => {
      socket.auth = {
        userId: user.id,
        typeUser: user.typeUser
      };
      socket.connect();
      socket.on('connect', () => {
        setConnected(true);

        socket.on(eventTypes.notification, async () => {
          await getNotification();
        });
      });
    };
    if (isAuthenticated && user && !connected) {
      startSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, socket]);

  return <SocketContext.Provider value={{ socket, connected }}>{children}</SocketContext.Provider>;
}

export { SocketProvider, SocketContext };
