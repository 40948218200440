import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import workOutline from '@iconify/icons-ic/outline-work';
import people from '@iconify/icons-ic/people';
import formFill from '@iconify/icons-fluent/form-20-filled';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import dashboardIcon from '@iconify/icons-carbon/dashboard';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MHidden, MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Inicio',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.root,
    onMobile: true
  },
  {
    label: 'Dashboard',
    icon: dashboardIcon,
    linkTo: PATH_DASHBOARD.dashboarding,
    onMobile: true
  },
  {
    label: 'Processos Seletivos',
    icon: workOutline,
    linkTo: PATH_DASHBOARD.selectiveProcesses.root,
    onMobile: false
  },
  {
    label: 'Buscar Candidato',
    icon: people,
    linkTo: PATH_DASHBOARD.candidate.root,
    onMobile: false
  },
  {
    label: 'Testes Personalizados',
    icon: formFill,
    linkTo: PATH_DASHBOARD.questionSheet.root,
    onMobile: false
  },
  {
    label: 'Configuração',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account,
    onMobile: true
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login);
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  let visibleMenuOptions = MENU_OPTIONS;

  if (user?.companyAnalist) {
    visibleMenuOptions = MENU_OPTIONS.filter(
      (option) => option.label !== 'Configuração' && option.label !== 'Dashboard'
    );
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          mt: '1rem',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.superAdmin?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {visibleMenuOptions.map((option) =>
          option.onMobile ? (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ) : (
            <MHidden key={option.label} width="mdUp">
              <MenuItem
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            </MHidden>
          )
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
