import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import { dealerJobsApi } from './api';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp, callback) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = window.setTimeout(() => {
    callback();
  }, timeLeft);
};

const setSession = (accessToken, callback = null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    dealerJobsApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    if (callback) {
      const { exp } = jwtDecode(accessToken);
      handleTokenExpired(exp, callback);
    }
  } else {
    localStorage.removeItem('accessToken');
    delete dealerJobsApi.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };
